h1 {
  font-size: calc(2rem + 2vmin);
  text-align: center;
  margin: 1rem;
}

h4 {
  font-size: calc(0.6rem + 2vmin);
  text-align: center;
  margin: 1rem;
}

h5 {
  font-size: calc(0.6rem + 2vmin);
  text-align: center;
  margin: 0.2rem;
  font-weight: 400;
}

h6 {
  font-size: calc(0.3rem + 2vmin);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.1rem;
  text-transform: uppercase;
  color: #757579;
}

a {
  color: #343435;
}

img {
  width: 100%;
  height: 100%;
}

.no-margin {
  margin: 0.1rem;
}

.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-container {
  font-size: calc(0.3rem + 2vmin);
  color: #757579;
  text-align: justify;
}

.large-info-box {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  flex-direction: row;
}
.large-info-box:nth-child(odd) {
  flex-direction: row-reverse;
}

.image-wrap {
  display: flex;
  flex: 60%;
  max-width: 60%;
}

.info-item-box {
  display: flex;
  flex: 1;
  margin: 1rem;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.info-box {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.header-image {
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: #757579;
  background-position: center left;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  height: 35rem;
  width: 90%;
  color: white;
}

.delimeter-title {
  display: border-box;
  border-bottom: 1px solid #757579;
  padding: 2rem;
}

@media screen and (max-width: 600px) {
  .image-wrap {
    max-width: 100%;
  }
  .large-info-box {
    flex-direction: column;
  }
  .large-info-box:nth-child(odd) {
    flex-direction: column;
  }
}
.control-button:active {
  transform: scale(0.5, 0.5);
}

.imgt-hug {
  position: absolute;
  left: 0;
  top: 0;
}
.thug-wrap {
  position: relative;
  height: auto;
  margin-top: 1rem;
}

.fadein {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 10s;
  -webkit-animation-direction: normal;
  animation-name: fade;
  animation-duration: 10s;
  animation-direction: normal;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form-wrap {
  border-bottom: 1px solid #757579;
  border-top: 1px solid #757579;
  padding: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  width: 80%;
}
.input-group {
  display: flex;
  flex-direction: row;
}

label {
  width: 18rem;
}

input[type="text"],
[type="email"],
select {
  flex: 1;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #757579;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="radio"],
[type="checkbox"] {
  flex: 1;
  padding: 12px 20px;
  margin: 20px 0;
}
textarea {
  flex: 1;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #757579;
  border-radius: 4px;
  box-sizing: border-box;
}
.submit-button {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
}

.controls {
  padding: 8px 10px;
  margin: 5px 0;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .input-group {
    flex-direction: column;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.icon {
  width: 1.5rem;
  height: 1.5rem;
}

div.rotate {
  width: 4rem;
  height: 4rem;
  padding-bottom: 11px;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
